import axios from 'axios';

export async function sendMsgToBedrockAI(text) {    
  const url = `https://foonh5n890.execute-api.us-west-2.amazonaws.com/dev/chatService`;
  const data = {
   model: "Bedrock-JURRASIC",
   "messages": text 
 };
   // Make a request to BEDROCK API
   try {
     const response = await axios.post(
       url,
      data,
       {
         headers: {
           'Accept': 'application/json',
           'x-api-key': `ETCiZDNa9f7tuM2edY9uL9v4TLuhuJU21Ux3AsKd`,
         },
       }
     );  
    return response.data.body;
}
catch (error) {
    console.error('An error occurred:', error);
  }
}
